import React from 'react';
import './Back.scss';
import {ReactComponent as BackIcon} from '../../assets/icons/back.svg';
import {useNavigate} from "react-router-dom";

function Back() {

    const navigate = useNavigate();

    return (
        <div className="back" onClick={() => navigate(-2)}>
            <BackIcon/> Précédent
        </div>
    );

}

export default Back;
