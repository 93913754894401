import React from "react";
import "pathseg";
import Matter from "matter-js";
import T1 from "../assets/img/tram/T1.svg";
import T2 from "../assets/img/tram/T2.svg";
import T3a from "../assets/img/tram/T3a.svg";
import T3b from "../assets/img/tram/T3b.svg";
import T4 from "../assets/img/tram/T4.svg";
import T5 from "../assets/img/tram/T5.svg";
import T6 from "../assets/img/tram/T6.svg";
import T7 from "../assets/img/tram/T7.svg";
import T8 from "../assets/img/tram/T8.svg";
import T9 from "../assets/img/tram/T9.svg";
import T10 from "../assets/img/tram/T10.svg";
import T11 from "../assets/img/tram/T11.svg";
import T12 from "../assets/img/tram/T12.svg";
import T13 from "../assets/img/tram/T13.svg";
import T1Hover from "../assets/img/tram/hover/T1.svg";
import T2Hover from "../assets/img/tram/hover/T2.svg";
import T3aHover from "../assets/img/tram/hover/T3a.svg";
import T3bHover from "../assets/img/tram/hover/T3b.svg";
import T4Hover from "../assets/img/tram/hover/T4.svg";
import T5Hover from "../assets/img/tram/hover/T5.svg";
import T6Hover from "../assets/img/tram/hover/T6.svg";
import T7Hover from "../assets/img/tram/hover/T7.svg";
import T8Hover from "../assets/img/tram/hover/T8.svg";
import T9Hover from "../assets/img/tram/hover/T9.svg";
import T10Hover from "../assets/img/tram/hover/T10.svg";
import T11Hover from "../assets/img/tram/hover/T11.svg";
import T12Hover from "../assets/img/tram/hover/T12.svg";
import T13Hover from "../assets/img/tram/hover/T13.svg";
import ValidateButton from "../components/ValidateButton/ValidateButton";
import Question from "../components/Question/Question";
import {Navigate} from "react-router";
import {createResponse} from "../services/responseService";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PageTransition from "../components/PageTransition";
import {StepContext} from "../contexts/StepContext";
import Back from "../components/Back/Back";

class Tram extends React.Component {
    static contextType = StepContext
    renderForCallback = null;

    constructor(props) {
        super(props);
        this.state = {
            choices: localStorage.getItem('question2tram') ? localStorage.getItem('question2tram').split(',') : [],
            user_id: localStorage.getItem('user_id'),
            forward: false,
            willRedirect: false,
        };
    }

    getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
    }

    setChoices(choice) {
        const index = this.state.choices.indexOf(choice);
        let choices = this.state.choices;
        if (index === -1) {
            choices.push(choice);
        } else {
            choices.splice(index, 1);
        }

        this.setState({choices: choices})
    }


    submitResponse = (e) => {
        let choicesString = this.state.choices.toString();
        let dataResponse = {
            'user_id': this.state.user_id,
            'question_id': 2,
            'response': choicesString,
        };
        createResponse(dataResponse).then((res) => {
            localStorage.setItem('question2tram', choicesString);
            this.setState({...this.state, willRedirect: true});
            setTimeout(() => {
                this.setState({...this.state, forward: true});
            }, this.props.delayOut * 800)
        }).catch(() => {
            toast("Une erreur s'est produite veuillez réessayer", {
                type: 'error',
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
        e.preventDefault();
    }

    componentWillUnmount() {
        Matter.Events.off(this.renderForCallback, 'afterRender');
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.context.setStep(this.props.stepNum)
        document.body.classList.add('line');
        document.body.classList.remove('end');
        document.body.classList.remove('mode');
        document.body.classList.remove('home-page');
        document.body.classList.remove('bus');
        const isLandscape = window.matchMedia('(orientation:landscape)').matches;
        const tramLines = ['T1', 'T2', 'T3a', 'T3b', 'T4', 'T5', 'T6', 'T7', 'T8', 'T9', 'T10', 'T11', 'T12', 'T13'];
        const tramLinesSVG = [T1, T2, T3a, T3b, T4, T5, T6, T7, T8, T9, T10, T11, T12, T13];
        const tramLinesSVGH = [T1Hover, T2Hover, T3aHover, T3bHover, T4Hover, T5Hover, T6Hover, T7Hover, T8Hover, T9Hover, T10Hover, T11Hover, T12Hover, T13Hover];
        const semicircleMaxRadius = 120;
        const maxScreenSize = window.innerWidth < 812 ? 375 : 812;
        const width = window.innerWidth > maxScreenSize ? maxScreenSize : window.innerWidth;
        let height = !isLandscape ? window.innerHeight - 147.5 :  document.querySelector('.App').clientHeight - 147.5; // 56px .page-wrapper + 69.5 h1 + 22px with the new text little
        if(window.innerWidth > 812){
            height = window.innerHeight - 208.5 // 100px .page-wrapper + 82px h1 + 26.5px .description-title
        }
        const isTinyScreen = window.innerWidth < 375;
        const isLargerMobile = window.innerWidth < 812;
        const radius = isTinyScreen ? 24 : (isLargerMobile ? 32 : 56);
        const radiusLittle = 16;
        const numberLittle = 10;
        const scaleIsNotActive = isTinyScreen ? 0.6 : (isLargerMobile ? 0.8 : 1.4);
        const scaleIsActive = isTinyScreen ? 1.4 : (isLargerMobile ? 1.3 : 1.5);
        const scaleIsActiveSvg = isTinyScreen ? 0.8 : (isLargerMobile ? 1 : 1.8);
        const Engine = Matter.Engine,
            Render = Matter.Render,
            World = Matter.World,
            Bodies = Matter.Bodies,
            Mouse = Matter.Mouse,
            Composite = Matter.Composite,
            MouseConstraint = Matter.MouseConstraint;
        let engine = Engine.create();
        let render = Render.create({
            element: this.refs.scene,
            engine: engine,
            options: {
                width: width,
                height: height,
                background: '#FFF',
                wireframes: false
            }
        });
        Render.setPixelRatio(render,  Math.ceil(window.devicePixelRatio));
        this.renderForCallback = render;
        let bodies = [];

        const loadImage = (url, onSuccess, onError) => {
            const img = new Image();
            img.onload = () => {
                onSuccess(img.src);
            };
            img.onerror = onError();
            img.src = url;
        };

        tramLines.forEach((line, num) => {
            const isActive   = this.state.choices.includes(line);
            const radiusBody = isActive ? radius * scaleIsActive : radius;
            const lineSVG    = isActive ? tramLinesSVGH[`${num}`] : tramLinesSVG[`${num}`];

            bodies.push(Bodies.circle(this.getRandomArbitrary(radiusBody, width - radiusBody), this.getRandomArbitrary(0, height / 2), radiusBody, {
                num: num,
                value: line,
                watchClick: true,
                this: this,
                render: {
                    sprite: {
                        texture: lineSVG,
                        xScale: isActive ? scaleIsActiveSvg : scaleIsNotActive,
                        yScale: isActive ? scaleIsActiveSvg : scaleIsNotActive
                    }
                },
                isActive: isActive
            }));
        });

        for (let i = 0; i < numberLittle; i++) {
            bodies.push(Bodies.circle(this.getRandomArbitrary(radius, width - radiusLittle), this.getRandomArbitrary(0, height / 2), radiusLittle, {
                watchClick: false,
                render: {
                    fillStyle: "#F2F2F2"
                }
            }));
        }

        const scale = window.innerWidth < maxScreenSize ? (window.innerWidth / maxScreenSize) : 1;
        const semicircle = Matter.Bodies.circle(width / 2, height, semicircleMaxRadius * scale, {
            isStatic: true,
            render: {
                fillStyle: '#0E44AB', strokeStyle: '#0E44AB',
            },
        });

        Matter.Body.scale(semicircle, scale, scale);

        let ground = Bodies.rectangle(width / 2, height + 5, width, 10, {
            isStatic: true, render: {
                strokeStyle: 'transparent'
            }
        });
        let celling = Bodies.rectangle(width / 2, -5, width, 10, {
            isStatic: true, render: {
                strokeStyle: 'transparent'
            }
        });
        let wallLeft = Bodies.rectangle(-5, height / 2, 10, height, {
            isStatic: true, render: {
                strokeStyle: 'transparent'
            }
        });
        let wallRight = Bodies.rectangle(width + 5, height / 2, 10, height, {
            isStatic: true, render: {
                strokeStyle: 'transparent'
            }
        });
        bodies.push(semicircle, ground, wallLeft, wallRight, celling)

        // add mouse control
        let mouse = Mouse.create(render.canvas),
            mouseConstraint = MouseConstraint.create(engine, {
                mouse: mouse,
                constraint: {
                    stiffness: 0.2,
                    render: {
                        visible: false
                    }
                }
            });

        World.add(engine.world, bodies);

        Matter.Events.on(mouseConstraint, "mousedown", function (event) {
            let foundPhysics = Matter.Query.point(Composite.allBodies(engine.world), event.mouse.position);
            if (foundPhysics[0] && foundPhysics[0].watchClick) {
                let texture;
                if (foundPhysics[0].isActive) {
                    let newRadius = radius / foundPhysics[0].circleRadius;
                    Matter.Body.scale(foundPhysics[0], newRadius, newRadius);
                    foundPhysics[0].render.sprite.xScale = scaleIsNotActive;
                    foundPhysics[0].render.sprite.yScale = scaleIsNotActive;
                    texture = tramLinesSVG[`${foundPhysics[0].num}`];
                    foundPhysics[0].isActive = false;
                    foundPhysics[0].this.setChoices(foundPhysics[0].value);
                } else {
                    Matter.Body.scale(foundPhysics[0], scaleIsActive, scaleIsActive);
                    foundPhysics[0].render.sprite.xScale = scaleIsActiveSvg;
                    foundPhysics[0].render.sprite.yScale = scaleIsActiveSvg;
                    texture = tramLinesSVGH[`${foundPhysics[0].num}`];
                    foundPhysics[0].isActive = true;
                    foundPhysics[0].this.setChoices(foundPhysics[0].value);
                }
                loadImage(
                    texture,
                    url => {
                        foundPhysics[0].render.sprite.texture = url;
                    },
                    () => {
                    }
                );
            }
        });

        Matter.Runner.run(engine);
        Matter.Render.run(render);

        Matter.Events.on(render, "afterRender", () => {
            engine.world.bodies.forEach((body) => {
                if (body.watchClick ) {
                    if (body.angle > 0.3) {
                        Matter.Body.setAngle(body, 0.3);
                    } else if (body.angle < -0.3) {
                        Matter.Body.setAngle(body, -0.3);
                    }
                }
            });
        });
    }

    render() {
        return (
            <>
                {this.state.forward ? <Navigate to={this.props.redirect}/> :
                    <div className="page-wrapper">
                        <PageTransition
                            redirecting={this.state.willRedirect}
                            delay={this.props.delayOut}
                            name="tram"
                        />
                        <div ref="scene" className='container-button'>
                            <ToastContainer role="alert" autoClose={5000}/>
                            <Back />
                            <Question title="En général, vous voyagez..." bold="sur quelle(s) ligne(s) ?"
                                      little="(plusieurs choix possibles)"/>
                            <svg style={{'display': 'none'}}>
                                <path ref="path"
                                      d="M120,120 L240,120 C240,54.084507 185.915493,0 120,0 C54.084507,0 0,54.084507 0,120 L120,120 Z"
                                      id="Path"></path>
                            </svg>
                            <ValidateButton disabled={this.state.choices.length === 0} onClick={this.submitResponse}/>
                        </div>
                    </div>}
            </>

        );
    }
}

export default Tram;
