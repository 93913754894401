import React from "react";
import './Bus.scss';
import "pathseg";
import ValidateButton from "../../components/ValidateButton/ValidateButton";
import Question from "../../components/Question/Question";
import {Navigate} from "react-router";
import {createResponse} from "../../services/responseService";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {busData} from '../../data/referentiel-des-lignes';
import PageTransition from "../../components/PageTransition";
import {StepContext} from "../../contexts/StepContext";
import Back from "../../components/Back/Back";

class Bus extends React.Component {
    static contextType = StepContext


    constructor(props) {
        super(props);
        this.state = {
            choices: localStorage.getItem('question2bus') ? localStorage.getItem('question2bus').split(',') : [],
            forward: false,
            willRedirect: false,
            user_id: localStorage.getItem('user_id'),
        };
    }

    componentDidMount() {
        // window.scrollTo(0, 0);
        this.context.setStep(this.props.stepNum);
        document.body.classList.remove('mode');
        document.body.classList.remove('home-page');
        document.body.classList.remove('end');
        document.body.classList.remove('line');
        document.body.classList.add('bus');

    }

    setChoices(choice) {
        const index = this.state.choices.indexOf(choice);
        let choices = this.state.choices;
        if (index === -1) {
            choices.push(choice);
        } else {
            choices.splice(index, 1);
        }
        this.setState({choices: choices})
    }


    submitResponse = () => {
        let choicesString = this.state.choices.toString();
        let dataResponse = {
            'user_id': this.state.user_id,
            'question_id': 2,
            'response': choicesString,
        };
        createResponse(dataResponse).then((res) => {
            localStorage.setItem('question2bus', choicesString);
            this.setState({...this.state, willRedirect: true});
                            document.body.classList.add('gif');
            setTimeout(() => {
                this.setState({...this.state, forward: true});
                                document.body.classList.remove('gif');
            }, this.props.delayOut * 800)
        }).catch(() => {
            toast("Une erreur s'est produite veuillez réessayer", {
                type: 'error',
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    selectBus = (e) => {
        let colorBg = e.currentTarget.getAttribute('datacolorbg');
        let colorText = e.currentTarget.getAttribute('datacolortext');
        let nameLine = e.currentTarget.getAttribute('datanameline');
        if (e.target.style.length > 0) {
            e.target.style = {};
        } else {
            e.target.style.backgroundColor = '#' + colorBg;
            e.target.style.color = '#' + colorText;
        }
        this.setChoices(nameLine);
        e.preventDefault()
    }
    getStyle = (data) => {
        return {
            backgroundColor: '#' + data.colourweb_hexa,
            color: '#' + data.textcolourweb_hexa
        }
    }

    render() {
        return (
            <>
                {this.state.forward ? <Navigate to={this.props.redirect}/> :
                    <>
                        <PageTransition
                            redirecting={this.state.willRedirect}
                            delay={this.props.delayOut}
                            name="bus"
                        />
                        <div className='page-wrapper bus-wrapper'>
                            <ToastContainer role="alert" autoClose={5000}/>
                            <Back />
                            <Question title="En général, vous voyagez..." bold="sur quelle(s) ligne(s) ?"
                                      little="(plusieurs choix possibles)"/>
                            <div className="container-buses">
                                <div className="column-bus">
                                    {busData.map((data, key) => {
                                        if (this.state.choices.includes(data.name_line)) {
                                            return (
                                                <div key={key} className='bus' datanameline={data.name_line}
                                                     datacolorbg={data.colourweb_hexa}
                                                     datacolortext={data.textcolourweb_hexa}
                                                     style={this.getStyle(data)}
                                                     onClick={this.selectBus}>
                                                    {data.name_line}
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <div key={key} className='bus' datanameline={data.name_line}
                                                     datacolorbg={data.colourweb_hexa}
                                                     datacolortext={data.textcolourweb_hexa}
                                                     onClick={this.selectBus}>
                                                    {data.name_line}
                                                </div>
                                            )
                                        }
                                        })
                                    }
                                </div>
                            </div>
                            <div className="hidden-button">
                            <ValidateButton disabled={this.state.choices.length === 0} onClick={this.submitResponse}/>
                            </div>
                        </div>
                    </>
                }
            </>
        );
    }
}

export default Bus;
