import React from "react";
import "pathseg";
import Matter from "matter-js";
import Titre1 from "../assets/img/titre/imagineR.svg";
import Titre2 from "../assets/img/titre/navigoLibertePlus2.svg";
import Titre3 from "../assets/img/titre/navigoEasy.svg";
import Titre4 from "../assets/img/titre/navigoSemaineMois.svg";
import Titre5 from "../assets/img/titre/navigoAnnuel.svg";
import Titre6 from "../assets/img/titre/amethyste.svg";
import Titre7 from "../assets/img/titre/ticketPlus.svg";
import Titre8 from "../assets/img/titre/autres.svg";
import Titre1Hover from "../assets/img/titre/hover/imagineRHover.svg";
import Titre2Hover from "../assets/img/titre/hover/navigoLibertePlusHover2.svg";
import Titre3Hover from "../assets/img/titre/hover/navigoEasyHover.svg";
import Titre4Hover from "../assets/img/titre/hover/navigoSemaineMoisHover.svg";
import Titre5Hover from "../assets/img/titre/hover/navigoAnnuelHover.svg";
import Titre6Hover from "../assets/img/titre/hover/amethysteHover.svg";
import Titre7Hover from "../assets/img/titre/hover/ticketPlusHover.svg";
import Titre8Hover from "../assets/img/titre/hover/autresHover.svg";
import ValidateButton from "../components/ValidateButton/ValidateButton";
import Question from "../components/Question/Question";
import {Navigate} from "react-router";
import {createResponse} from "../services/responseService";
import {toast, ToastContainer} from "react-toastify";
import PageTransition from "../components/PageTransition";
import {StepContext} from "../contexts/StepContext";
import {isMobile} from "react-device-detect";
import Back from "../components/Back/Back";

class TransportTitle extends React.Component {
    static contextType = StepContext;
    renderForCallback = null;

    constructor(props) {
        super(props);
        this.state = {
            choices: localStorage.getItem('question3') ? localStorage.getItem('question3').split(',') : [],
            user_id: localStorage.getItem('user_id'),
            forward: false,
            willRedirect: false,
        };
    }

    getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
    }

    setChoices(choice) {
        const index = this.state.choices.indexOf(choice);
        let choices = this.state.choices;
        if (index === -1) {
            choices.push(choice);
        } else {
            choices.splice(index, 1);
        }
        this.setState({...this.state, choices})
    }


    submitResponse = (e) => {
        let choicesString = this.state.choices.toString();
        let dataResponse = {
            'user_id': this.state.user_id,
            'question_id': 3,
            'response': choicesString,
        };
        createResponse(dataResponse).then((res) => {
            localStorage.setItem('question3', choicesString);
            this.setState({...this.state, willRedirect: true});
            setTimeout(() => {
                this.setState({...this.state, forward: true});
            }, this.props.delayOut * 500)
        }).catch(() => {
            toast("Une erreur s'est produite veuillez réessayer", {
                type: 'error',
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
        e.preventDefault();
    }

    componentWillUnmount() {
        Matter.Events.off(this.renderForCallback, 'afterRender');
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.context.setStep(this.props.stepNum);
        document.body.classList.add('line');
        document.body.classList.remove('end');
        document.body.classList.remove('mode');
        document.body.classList.remove('home-page');
        document.body.classList.remove('bus');
        const isLandscape = window.matchMedia('(orientation:landscape)').matches;
        const semicircleMaxRadius = 120;
        const maxScreenSize       = isMobile || window.innerWidth < 812 ? 375 : 812;
        const width               = window.innerWidth > maxScreenSize ? maxScreenSize : window.innerWidth;
        let height = !isLandscape ? window.innerHeight - 125.5 :  document.querySelector('.App').clientHeight - 125.5; // 56px = taille header en px + 69.5 h1
        if(window.innerWidth > 812){
            height = window.innerHeight - 182 // 100px .page-wrapper + 82px h1
        }
        const isTinyMobile        = window.innerWidth < 375;
        const isLargerMobile      = window.innerWidth < 812;
        const radiusLittle        = 16;
        const numberLittle        = 10;
        const scaleIsNotActive = isTinyMobile ? 0.7 : (isLargerMobile ? 0.9 : 1.3);
        const scaleIsActive    = isTinyMobile ? 1.5 : (isLargerMobile ? 1.3 : 1.3);
        const scaleIsActiveSvg = isTinyMobile ? 1 : (isLargerMobile ? 1.1 : 1.5);
        const transportTitles     = [
            {
                label: 'Imagine R',
                svg: Titre1,
                svgHover: Titre1Hover,
                size: [183, 59],
                position: [182 / 2, 40]
            },
            {
                label: 'Autres',
                svg: Titre8,
                svgHover: Titre8Hover,
                size: [200, 55],
                position: [200 / 2, 100]
            },
            {
                label: 'Navigo Liberté',
                svg: Titre2,
                svgHover: Titre2Hover,
                size: [270, 57],
                position: [(width - 275 / 2), 50]
            },
            {
                label: 'Navigo Easy',
                svg: Titre3,
                svgHover: Titre3Hover,
                size: [209, 59],
                position: [233 / 2, 60]
            },
            {
                label: 'Navigo Mois et Semaine',
                svg: Titre4,
                svgHover: Titre4Hover,
                size: [378, 56],
                position: [width / 2, 70]
            },
            {
                label: 'Navigo Annuel',
                svg: Titre5,
                svgHover: Titre5Hover,
                size: [244, 59],
                position: [width / 2, 80]
            },
            {
                label: 'Améthyste',
                svg: Titre6,
                svgHover: Titre6Hover,
                size: [192, 59],
                position: [width - (150 / 2), 90]
            },
            {
                label: 'Ticket t+',
                svg: Titre7,
                svgHover: Titre7Hover,
                size: [165, 59],
                position: [191 / 2, 100]
            },
        ]
        // const imageRotation = [-20, -57, 15, 0, 0, -32, 0];
        const Engine = Matter.Engine,
            Render = Matter.Render,
            World = Matter.World,
            Bodies = Matter.Bodies,
            Mouse = Matter.Mouse,
            Composite = Matter.Composite,
            MouseConstraint = Matter.MouseConstraint;
        let engine = Engine.create();
        let render = Render.create({
            element: this.refs.scene,
            engine: engine,
            options: {
                width: width,
                height: height,
                background: '#FFF',
                wireframes: false
            }
        });
        Render.setPixelRatio(render,  Math.ceil(window.devicePixelRatio));
        this.renderForCallback = render;
        let bodies = [];

        const loadImage = (url, onSuccess, onError) => {
            const img = new Image();
            img.onload = () => {
                onSuccess(img.src);
            };
            img.onerror = onError();
            img.src = url;
        };

        transportTitles.forEach((title) => {
            const isActive   = this.state.choices.includes(title['label']);
            const widthElem  = title['size'][0] * (isActive ? scaleIsActiveSvg : scaleIsNotActive);
            const heightElem = title['size'][1] * (isActive ? scaleIsActiveSvg : scaleIsNotActive);
            const x = title['position'][0];
            const y = title['position'][1];
            bodies.push(Bodies.rectangle(x, y, widthElem, heightElem, {
                svg: title['svg'],
                hoverSvg: title['svgHover'],
                value: title['label'],
                widthElem,
                watchClick: true,
                this: this,
                render: {
                    sprite: {
                        texture: isActive ? title['svgHover'] : title['svg'],
                        xScale: isActive ? scaleIsActiveSvg : scaleIsNotActive,
                        yScale: isActive ? scaleIsActiveSvg : scaleIsNotActive
                    },
                },
                isActive: isActive
            }));
        });

        for (let i = 0; i < numberLittle; i++) {
            bodies.push(Bodies.circle(this.getRandomArbitrary(radiusLittle, width - radiusLittle), this.getRandomArbitrary(0, height / 2), radiusLittle, {
                watchClick: false,
                render: {
                    fillStyle: "#F2F2F2"
                }
            }));
        }

        const scale      = window.innerWidth < maxScreenSize ? (window.innerWidth / maxScreenSize) : 1;
        const semicircle = Matter.Bodies.circle(width / 2, height, semicircleMaxRadius * scale, {
            isStatic: true,
            render: {
                fillStyle: '#0E44AB', strokeStyle: '#0E44AB',
            },
        });

        Matter.Body.scale(semicircle, scale, scale);

        let ground = Bodies.rectangle(width / 2, height + 5, width, 10, {
            isStatic: true, render: {
                strokeStyle: 'transparent'
            }
        });
        let celling = Bodies.rectangle(width / 2, -5, width, 10, {
            isStatic: true, render: {
                strokeStyle: 'transparent'
            }
        });
        let wallLeft = Bodies.rectangle(-5, height / 2, 10, height, {
            isStatic: true, render: {
                strokeStyle: 'transparent'
            }
        });
        let wallRight = Bodies.rectangle(width + 5, height / 2, 10, height, {
            isStatic: true, render: {
                strokeStyle: 'transparent'
            }
        });
        bodies.push(semicircle, ground, wallLeft, wallRight, celling)

        // add mouse control
        let mouse = Mouse.create(render.canvas),
            mouseConstraint = MouseConstraint.create(engine, {
                mouse: mouse,
                constraint: {
                    stiffness: 0.2,
                    render: {
                        visible: false
                    }
                }
            });

        World.add(engine.world, bodies);

        Matter.Events.on(mouseConstraint, "mousedown", function (event) {
            let foundPhysics = Matter.Query.point(Composite.allBodies(engine.world), event.mouse.position);
            if (foundPhysics[0] && foundPhysics[0].watchClick) {
                let texture;
                if (foundPhysics[0].isActive) {
                    let newScale = foundPhysics[0].widthElem / (foundPhysics[0].widthElem * scaleIsActive);
                    Matter.Body.scale(foundPhysics[0], newScale, newScale);
                    foundPhysics[0].render.sprite.xScale = scaleIsNotActive;
                    foundPhysics[0].render.sprite.yScale = scaleIsNotActive;
                    texture = foundPhysics[0].svg;
                    foundPhysics[0].isActive = false;
                    foundPhysics[0].this.setChoices(foundPhysics[0].value);
                } else {
                    //unscale other
                    engine.world.bodies.forEach((body) => {
                        if (body.isActive) {
                            let newScale = body.widthElem / (body.widthElem * scaleIsActive);
                            Matter.Body.scale(body, newScale, newScale);
                            body.render.sprite.xScale = scaleIsNotActive;
                            body.render.sprite.yScale = scaleIsNotActive;
                            texture = body.svg;
                            body.isActive = false;
                            body.this.setChoices(body.value);
                            loadImage(
                                texture,
                                url => {
                                    body.render.sprite.texture = url;
                                },
                                () => {
                                }
                            );
                        }
                    });
                    Matter.Body.scale(foundPhysics[0], scaleIsActive, scaleIsActive);
                    foundPhysics[0].render.sprite.xScale = scaleIsActiveSvg;
                    foundPhysics[0].render.sprite.yScale = scaleIsActiveSvg;
                    texture = foundPhysics[0].hoverSvg;
                    foundPhysics[0].isActive = true;
                    foundPhysics[0].this.setChoices(foundPhysics[0].value);
                }
                loadImage(
                    texture,
                    url => {
                        foundPhysics[0].render.sprite.texture = url;
                    },
                    () => {
                    }
                );
            }
        });

        Matter.Runner.run(engine);
        Matter.Render.run(render);

        Matter.Events.on(render, "afterRender", () => {
            engine.world.bodies.forEach((body) => {
                if (body.watchClick ) {
                    if (body.angle > 0.4) {
                        Matter.Body.setAngle(body, 0.4);
                    } else if (body.angle < -0.4) {
                        Matter.Body.setAngle(body, -0.4);
                    }
                }
            });
        });
    }

    render() {
        return (
            <>
                {this.state.forward
                    ? <Navigate to={this.props.redirect}/>
                    : <div className="page-wrapper">
                        <PageTransition
                            redirecting={this.state.willRedirect}
                            delay={this.props.delayOut}
                            name="title"
                        />
                        <div ref="scene" className='container-button'>
                            <ToastContainer role="alert" autoClose={5000}/>
                            <Back />
                            <Question title="En général, vous voyagez..." bold="avec quel titre de transport ?"/>
                            <ValidateButton disabled={this.state.choices.length === 0} onClick={this.submitResponse}/>
                        </div>
                    </div>
                }
            </>
        );
    }
}

export default TransportTitle;
