import React from "react";
import './DropZone.scss';
import Icon from "../Icon/Icon";

function DropZone(props) {
    return (
        <div className={`dropzone ${props.items.length > 0 ? 'dropped' : ''}`}>
            {props.items.length > 0 ? (
                    <li className='container-dropzone' onClick={props.emptyDropZone}>
                        <div
                            style={{backgroundColor: props.items[0].color}}
                            className='bubble'
                        >
                            <Icon icons={props.items[0].icon}/>
                        </div>
                    </li>
                )
                :
                (
                    <div className='container-dropzone'>
                        <svg className='zone' width="116" height="116" viewBox="0 0 116 116" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <circle cx="58" cy="58" r="57.4" stroke="black" strokeWidth="1.5"
                                    strokeDasharray="3 10"/>
                        </svg>
                        <p className="placeholder">{props.placeholder}</p>
                    </div>
                )
            }
        </div>
    );
}

export default DropZone;
