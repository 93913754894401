import React, {useContext, useEffect, useState} from "react";
import "./End.scss";
import Button from "../../components/Button/Button";
import ConfettiGenerator from "confetti-js";
import {StepContext} from "../../contexts/StepContext";

function End() {
    const {setStep} = useContext(StepContext);
    const [firstname] = useState(localStorage.getItem('firstname') ? localStorage.getItem('firstname') : '');
    useEffect(() => {
        // window.scrollTo(0, 0);
        setStep(-1)
        Object.keys(localStorage).filter(function(key) {
            return /^question/.test(key);
        }).map(function(key) {
            localStorage.removeItem(key);
        });
        document.body.classList.add('end');
        document.body.classList.remove('line');
        document.body.classList.remove('home-page');
        document.body.classList.remove('bus');
        document.body.classList.remove('mode');
        const confetti = new ConfettiGenerator({size: 2});
        confetti.render();
        return () => confetti.clear();
    })

    return (
        <>
            <div className="bg"></div>
            <canvas id='confetti-holder'></canvas>
            <div className="end-step">
                <div>
                    <h1>Merci <span>{firstname} !</span> </h1>
                    <div className='text'>
                        <p>C'est déjà fini. <br/> On vous prépare <br/> un programme sur mesure. <br/>En attendant...<br/>pleins de contenus vous <br/> attendent sur <i>ma</i>RATP</p>
                    </div>
                    <Button link='https://maratp.ratp.fr/' label="On vous emmène ?"/>
                </div>
            </div>
        </>
    )
}

export default End;
