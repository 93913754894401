import React from 'react';

const WarningIcon = ({color}) => {
    return (
        <svg className="input-advice-icon" width="349px" height="348px" viewBox="0 0 349 348" version="1.1" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>warningIcon</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="noun-warning-3554084" transform="translate(0.870550, 0.000000)" fill={color}
                   fillRule="nonzero">
                    <path
                        d="M173.598,0 C127.555,0 83.399,18.293 50.848,50.848 C18.293,83.403 -2.84217094e-14,127.559 -2.84217094e-14,173.598 C-2.84217094e-14,219.637 18.293,263.797 50.848,296.348 C83.403,328.903 127.559,347.196 173.598,347.196 C219.637,347.196 263.797,328.903 296.348,296.348 C328.903,263.793 347.196,219.637 347.196,173.598 C347.196,127.559 328.903,83.399 296.348,50.848 C263.793,18.293 219.637,0 173.598,0 Z M173.598,313.6 C136.469,313.6 100.86,298.85 74.602,272.596 C48.348,246.338 33.598,210.729 33.598,173.6 C33.598,136.471 48.348,100.862 74.602,74.604 C100.86,48.35 136.469,33.6 173.598,33.6 C210.727,33.6 246.336,48.35 272.594,74.604 C298.848,100.862 313.598,136.471 313.598,173.6 C313.598,210.729 298.848,246.338 272.594,272.596 C246.336,298.85 210.727,313.6 173.598,313.6 Z"
                        id="Shape"></path>
                    <polygon id="Path" points="156.798 84 190.4 84 190.4 190.4 156.798 190.4"></polygon>
                    <path
                        d="M190.398,240.8 C190.398,250.0773 182.8746,257.601 173.597,257.601 C164.3194,257.601 156.796,250.0776 156.796,240.8 C156.796,231.5227 164.3194,223.999 173.597,223.999 C182.8746,223.999 190.398,231.5224 190.398,240.8"
                        id="Path"></path>
                </g>
            </g>
        </svg>
    )
}
export default WarningIcon;
