import React, {useContext, useEffect, useState} from "react";
import {v4 as uuid} from "uuid";
import Boxes from "../components/Boxes/Boxes";
import DropZone from "../components/DropZone/DropZone";
import Question from "../components/Question/Question";
import ValidateButton from "../components/ValidateButton/ValidateButton";
import PageTransition from "../components/PageTransition";
import {Navigate} from "react-router-dom";
import {createResponse} from "../services/responseService";
import {toast} from "react-toastify";
import {StepContext} from "../contexts/StepContext";
import Back from "../components/Back/Back";

const MODES = [
    {id: uuid(), label: "Bus", icon: 'bus', color: '#FFB400', redirect: '/lignes-bus'},
    {id: uuid(), label: "RER", icon: 'rer', color: '#64E24A', redirect: '/lignes-rer'},
    {id: uuid(), label: "Métro", icon: 'metro', color: '#C929F9', redirect: '/lignes-metro'},
    {id: uuid(), label: "Transilien", icon: 'transilien', color: '#FF6C00', redirect: '/lignes-transilien'},
    {
        id: uuid(),
        label: "Mobilités douces",
        icon: 'mobilites_douces',
        color: '#00B2FF',
        redirect: '/titres-de-transport'
    },
    {id: uuid(), label: "Tram", icon: 'tram', color: '#F9EB0D', redirect: '/lignes-tram'},
];


function Mode({delayOut, stepNum}) {
    document.body.classList.remove('line');
    document.body.classList.remove('home-page');
    document.body.classList.remove('end');
    document.body.classList.remove('bus');
    document.body.classList.add('mode');
    const [answer] = React.useState(JSON.parse(localStorage.getItem('question_1')));
    const [user_id] = React.useState(localStorage.getItem('user_id'));
    const [BoxesItems] = React.useState(MODES, []);
    const [DropZoneItems, setDropZoneItems] = React.useState(answer ? answer.itemSelected : []);
    const [forward, setForward] = useState(false);
    const [willRedirect, setWillRedirect] = useState(false);
    const [redirect, setRedirect] = useState("");
    const {setStep} = useContext(StepContext);

    useEffect(() => {
        setStep(stepNum);
        if (answer) {
            setDropZoneItems(answer.itemSelected)
        }
       // window.scrollTo(0, 0);
    }, [setStep, stepNum, answer]);

    function handleSubmit() {
        let dataResponse = {
            'user_id': user_id,
            'question_id': 1,
            'response': DropZoneItems[0]['label'],
        };
        localStorage.setItem('question_1', JSON.stringify({
                itemSelected: DropZoneItems
        }));
        createResponse(dataResponse).then((res) => {
            setWillRedirect(true);
            setRedirect(DropZoneItems[0]['redirect']);
            document.body.classList.add('gif');
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
            setTimeout(() => {
                setForward(true);
                document.body.classList.remove('gif');
            }, delayOut * 500)
        }).catch(() => {
            toast("Une erreur s'est produite veuillez réessayer", {
                type: 'error',
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    function handleClick(item) {
        setDropZoneItems([item])
    }

    function emptyDropZone() {
        setDropZoneItems([])
    }

    return (
        forward
            ? <Navigate to={redirect} step={3}/>
            : <div className="mode page-wrapper">
                <PageTransition
                    redirecting={willRedirect}
                    delay={delayOut}
                    name="mode"
                />
                <Back />
                <Question title="En général, vous voyagez…" bold="avec quel moyen de transport ?"
                          little="(un seul choix possible)"/>
                <div className='mode-wrapper'>
                    <Boxes items={BoxesItems} selected={DropZoneItems} handleClick={handleClick}/>
                    <DropZone placeholder='Cliquez sur votre mode de transport préféré' items={DropZoneItems}
                              emptyDropZone={emptyDropZone}/>
                </div>
                <div className="hidden-button">
                    <ValidateButton
                        onClick={handleSubmit}
                        disabled={DropZoneItems.length === 0}
                    />
                </div>
            </div>

    )
}

export default Mode;
