import React from "react";
import './Input.scss';

const Input = (props) => {
   return (
       <input {...props} className={`input ${props.errors.length > 0 ? 'error' :'' }`} />
   )
}

export default Input
