import './Checkbox.scss';

const Checkbox = (props) => {
    return (
       <label className="container-checkbox">
            <span className="text">{props.text} <a href={props.link} target="_blank" rel="noreferrer">{props.linkLabel}</a>{props.end}</span>
            <input onChange={props.onChange} value={props.value} name={props.name} type="checkbox" className="input" />
            <span className="checkmark"></span>
       </label>
   )
}

export default Checkbox
