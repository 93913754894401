import React, {Component} from 'react';
import './Button.scss';

class Button extends Component {
    render() {
        return this.props.type ? (
            <button className="button" type={this.props.type} aria-disabled={this.props.disabled} disabled={this.props.disabled}><span>{this.props.label}</span></button>
        ) : (
            <a href={this.props.link} target="_blank" rel="noreferrer" className="button">{this.props.label}</a>
        );
    }
}

export default Button;
