import React, {Component} from 'react';
import './Question.scss';
class Question extends Component {
    render() {
        return (
            <>
                <h1>{this.props.title} <br/><span>{this.props.bold}</span></h1>
                {this.props.little  &&
                    <p className="description-title">{this.props.little}</p>
                }
            </>
        );
    }
}

export default Question;
