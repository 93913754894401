import React, {useContext, useEffect, useState} from "react";
import InputMask from 'react-input-mask';
import {ReactComponent as Logo} from '../../assets/img/maratp.svg';
import './Home.scss';
import {useLocation} from "react-router-dom";
import {createUser} from "../../services/userService";
import HeaderText from "../../components/HeaderText/HeaderText";
import Input from "../../components/Input/Input";
import Checkbox from "../../components/Checkbox/Checkbox";
import Moment from 'moment';
import {browserName, browserVersion, isMobile} from 'react-device-detect';
import Button from "../../components/Button/Button";
import {Navigate} from "react-router";
import {toast, ToastContainer} from "react-toastify";
import validator from 'validator';
import {StepContext} from "../../contexts/StepContext";

import WarningIcon from "../../assets/icons/WarningIcon";

function Home({setFirstname}) {
    document.body.classList.add('home-page');
    document.body.classList.remove('mode');
    const browser = browserName;
    const version = browserVersion;
    const device = isMobile ? 'Mobile' : 'Desktop';
    const useradgent = window.navigator.userAgent;
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const ratp_id = params.get('ratp_id');
    const user_name = params.get('user_name');
    const utm_campaign = params.get('utm_campaign');
    const utm_content = params.get('utm_content');
    const utm_medium = params.get('utm_medium');
    const utm_source = params.get('utm_source');
    const utm_term = params.get('utm_term');
    const [formValue, setFormValue] = useState({
        lastname: "",
        firstname: "",
        email: "",
        birthdate: "",
    });
    const [isChecked, setIsChecked] = useState(false);
    const [mode, setMode] = useState(false);
    const [errors, setErrors] = useState({});
    const {setStep} = useContext(StepContext);
    useEffect(() => {
        window.scrollTo(0, 0);
        setStep(-1)
        if (ratp_id) {
            document.body.classList.add('with-pad');
        }
    }, [setStep, ratp_id])
    const userCreate = () => {
        let dataUser = {
            utm_campaign,
            utm_content,
            utm_medium,
            utm_source,
            utm_term,
            browser,
            version,
            device,
            useradgent
        };
        if (ratp_id) {
            dataUser.ratp_id = ratp_id;
            if (user_name) {
                dataUser.firstname = user_name;
            }
        } else {
            let date = formValue.birthdate;
            date = Moment(date, 'DD-MM-YYYY');
            date = date.format('YYYY-MM-DD');
            let finalFormValues = {...formValue};
            finalFormValues.birthdate = date;
            Object.assign(dataUser, finalFormValues);

        }

        createUser(dataUser).then((res) => {
            localStorage.setItem('user_id', res.user_id);
            if (ratp_id && user_name) {
                setFirstname(user_name)
                localStorage.setItem('firstname', user_name);
            } else if (formValue.firstname) {
                setFirstname(formValue.firstname)
                localStorage.setItem('firstname', formValue.firstname);

            }

            setMode(true);
        }).catch((err) => {
            toast("Une erreur s'est produite veuillez réessayer", {
                type: 'error',
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    const onHandleChecked = (e) => {
        setIsChecked(!isChecked)
    }

    const onHandleChange = (e) => {
        let {name, value} = e.target;
        setFormValue((prevState) => {
            return {
                ...prevState,
                [name]: value,
            };
        });
    }

    const handleSubmit = (e) => {

        let valid = true;
        let err = {};
        if (!ratp_id) {
            for (let [name, value] of Object.entries(formValue)) {
                if (validator.isEmpty(value)) {
                    err[name] = 'Le champ ne doit pas être vide';
                    valid = false;
                } else if (name === 'email') {
                    if (!validator.isEmail(value)) {

                        err.email = 'L\'email n\'est pas au bon format';
                        valid = false;
                    }
                } else if (name === 'birthdate') {
                    let date = Moment(value, 'DD-MM-YYYY');
                    let now = Moment().format('YYYY-MM-DD');
                    let age = Moment().diff(date, 'years', false);
                    let year = date.format('YYYY');
                    date = date.format('YYYY-MM-DD');
                    if (!validator.isDate(date) || date > now || date.length < 10 || age < 16 || value.includes('_') || year < 1900) {
                        if (date > now && validator.isDate(date)) {
                            err.birthdate = 'La date ne peut pas être supérieure à la date actuelle';
                        } else if (age < 16) {
                            err.birthdate = 'Vous devez avoir plus de 16 ans pour répondre à ce questionnaire.';
                        } else {
                            err.birthdate = 'La date de naissance n\'est pas correcte';
                        }
                        setErrors(err);
                        valid = false;
                    }
                }
            }
            setErrors(err);
        }
        if (valid) {
            userCreate();
        }
        e.preventDefault();
    }
    const {lastname, firstname, email, birthdate, accept} = formValue;

    if (mode) {
        Object.keys(localStorage).filter(function(key) {
            return /^question/.test(key);
        }).map(function(key) {
            localStorage.removeItem(key);
        });
        return <Navigate to="/mode"/>
    }

    return (
        <div className="home page-wrapper">
            <ToastContainer role="alert" autoClose={5000}/>
            <form method="post" className="form-container" onSubmit={handleSubmit}>
                {ratp_id || user_name ? <Logo className='logo'/> : false}
                <HeaderText name={user_name} ratpId={ratp_id}/>
                {!ratp_id ?
                    <div className="form">
                        <div className="form-group">
                            <Input
                                errors={`${errors.firstname !== undefined && errors.firstname.length > 0 ? ' error' : ''}`}
                                onChange={onHandleChange} placeholder="Prénom*" value={firstname} type="text"
                                name="firstname"/>
                            {errors.firstname !== undefined && errors.firstname.length > 0 &&
                                <div className="input-advice error"><WarningIcon color="#FFFFFF"/> {errors.firstname}</div>}
                        </div>
                        <div className="form-group">
                            <Input
                                errors={`${errors.lastname !== undefined && errors.lastname.length > 0 ? ' error' : ''}`}
                                onChange={onHandleChange} placeholder="Nom*" value={lastname} type="text"
                                name="lastname"/>
                            {errors.lastname !== undefined && errors.lastname.length > 0 &&
                                <div className="input-advice error"><WarningIcon color="#FFFFFF"/> {errors.lastname}
                                </div>}
                        </div>
                        <div className="form-group">
                            <Input
                                errors={`${errors.email !== undefined && errors.email.length > 0 ? ' error' : ''}`}
                                onChange={onHandleChange} placeholder="Email*" value={email} type="text"
                                name="email"/>
                            {errors.email !== undefined && errors.email.length > 0 &&
                                <div className="input-advice error"><WarningIcon color="#FFFFFF"/> {errors.email}</div>}
                        </div>
                        <div className="form-group">
                            <InputMask
                                className={`input ${errors.birthdate !== undefined && errors.birthdate.length > 0 ? ' error' : ''}`}
                                onChange={onHandleChange} placeholder="Date de naissance*"
                                value={birthdate} name="birthdate" mask='99-99-9999'
                            />
                            {errors.birthdate !== undefined && errors.birthdate.length > 0 &&
                                <div className="input-advice error"><WarningIcon color="#FFFFFF"/> {errors.birthdate}</div>}
                        </div>
                        <div className="form-group">
                            <p className="info-message">*Champs obligatoires.</p>
                        </div>
                        <Checkbox onChange={onHandleChecked} value={accept} name="accept"
                                  text="Oui ! J’accepte de recevoir les communications maRATP de manière personnalisée selon mon trajet : être informé(e) des actualités, profiter d’idées de sorties et d’offres exclusives. Voici notre"
                                  linkLabel="politique de confidentialité"
                                  link="https://www.ratp.fr/politique-generale-de-confidentialite"
                                  end="."/>
                    </div>
                    :
                    false}
                <Button type='submit' disabled={!ratp_id && !isChecked} label="C'est parti"/>
            </form>
        </div>
    )

}

export default Home
