import React from "react";
import './Copyable.scss';
import Icon from "../Icon/Icon";

function Copyable (props) {
    return (
        <React.Fragment>
            <li className='container-bubble'>
                <div index={props.index} onClick={() => props.handleClick(props.item)}>
                    <React.Fragment>
                        <div
                            style={{backgroundColor: props.item.color}}
                            className={`bubble ${props.selected ? 'hidden' : ''}`}
                        >
                            <Icon icons={props.item.icon}/>
                        </div>
                    </React.Fragment>
                </div>
                <p>{props.item.label}</p>
            </li>
        </React.Fragment>
    );
};

export default Copyable;
