import React, {useContext, useEffect, useRef} from 'react';
import "./PageTransition.scss";
import {gsap, Elastic} from "gsap";
import {TransitionContext} from "../contexts/TransitionContext";

const PageTransition = ({redirecting, delay, withoutGif = false, name}) => {
    const circleRef = useRef();
    const imgRef = useRef();
    const txtRef = useRef();
    const TL = useRef();
    const {text, gifsrc} = useContext(TransitionContext)[name]
    useEffect(() => {
        circleRef.current.classList.remove("end");
        gsap.from(circleRef.current, {scale: "9", zIndex: 102, duration: 0.3, delay: 0.3})
    }, []);
    useEffect(() => {
        if (redirecting && !withoutGif) {
            TL.current = gsap.timeline()
                .to(circleRef.current, {scale: "9", duration: 0.3, zIndex: 102, onComplete: function(){
                        circleRef.current.classList.add("end");
                    }})
                .to(imgRef.current, {autoAlpha: 1})
                .to(txtRef.current, {autoAlpha: 1, scale: 1, ease: Elastic.easeOut.config(1, 0.3), duration: 1})
        } else if (redirecting) {
            TL.current = gsap.timeline()
                .to(circleRef.current, {scale: "9", duration: 0.3, zIndex: 110})
        }
    }, [redirecting, withoutGif]);

    return (
        <div className="transition-overlay">

            {!withoutGif &&
            <div className="gif-img">
                <img src={gifsrc} ref={imgRef} alt={imgRef}/>
            </div>
            }

            {!withoutGif &&
            <div className={"text-wrapper"} style={{alignItems: text.position}}>
                <div className="text" style={{fontSize: `${text.fz}px`}} ref={txtRef}>
                    {text.content.map((line, i) => (
                        <h3 key={i}>{line}</h3>
                    ))}
                </div>
            </div>
            }
            <div className={`circle ${redirecting ? "front" : ""} ${withoutGif ? "end" : ""}`} ref={circleRef}></div>
        </div>
    )
}

export default PageTransition;
