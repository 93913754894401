import React, {Component} from 'react';
import './Bar.scss';
import {StepContext} from "../../contexts/StepContext";

class Bar extends Component {
    static contextType = StepContext;

    constructor(props) {
        super(props);
        this.state = {
            activePosition: 0,
        };
    }

    checkSteps() {
        if (this.context.step >= 0) {
            let checkpoint = document.querySelector('.checkpoint.active').getBoundingClientRect();
            let progress = document.querySelector('.progress').getBoundingClientRect();
            if ((checkpoint.x - progress.x) !== this.state.activePosition) {
                this.setState({activePosition: checkpoint.x - progress.x});
            }
        }
    }

    componentDidMount() {
        this.checkSteps();
    }

    componentDidUpdate() {
        this.checkSteps();
    }


    render() {
        return (
            <StepContext.Consumer>
                {({step, setStep}) => (
                    step >= 0 &&
                    <div className="progress-container">
                        <div className="progress">
                            <div className={`checkpoint ${0 === step ? 'active' : ''}`}/>
                            <div className={`checkpoint ${1 === step ? 'active' : ''}`}/>
                            <div className={`checkpoint ${2 === step ? 'active' : ''}`}/>
                            <div className={`checkpoint ${3 === step ? 'active' : ''}`}/>
                            <div style={{left: this.state.activePosition + 'px'}} className="checkpoint active-point">
                                <div className="round"></div>
                            </div>
                        </div>
                    </div>
                )}
            </StepContext.Consumer>
        );
    }
}

export default Bar;
