import './App.scss';
import React, {useState, useEffect} from "react";
import './styles.scss';
import Mode from "./pages/Mode";
import Line from "./pages/Line/Line";
import Bus from "./pages/Bus/Bus";
import {AnimatePresence} from "framer-motion";
import {
    Routes,
    Route,
    useLocation,
} from "react-router";
import Home from "./pages/Home/Home";
import TransportTitle from "./pages/TransportTitle";
import Infos from "./pages/Infos";
import Tram from "./pages/Tram";
import End from "./pages/End/End";
import Transilien from "./pages/Transilien";
import Rer from "./pages/Rer";
import {StepContext} from './contexts/StepContext';
import Bar from "./components/Bar/Bar";
import {TransitionContext} from "./contexts/TransitionContext";
import ProtectedRoute from "./components/ProtectedRoute";

function App() {
    let location = useLocation();
    const [step, setStep] = useState(-1);
    const [firstname, setFirstname] = useState("");
    useEffect(() => {
        window.addEventListener("orientationchange", function () {
            window.location.reload();
            window.scrollTo(0, 0);
        });
        if (window.innerWidth < 812) {
            let vh = window.innerHeight * 0.01;
            let vw = window.innerWidth * 0.01;
            if (window.matchMedia("(orientation: portrait)").matches) {
                document.documentElement.style.setProperty('--vh', `${vh}px`);
            }
            if (window.matchMedia("(orientation: landscape)").matches) {
                document.documentElement.style.setProperty('--vh', `${vw}px`);
            }
        }
    })
    const transitionsContent = {
        mode: {
            gifsrc: "https://media.giphy.com/media/EGEyuRL74DeFxGw29k/giphy.gif",
            text: {
                content: [`Très belle réponse ${firstname}...`],
                fz: 24,
                position: "center",
            }
        },
        line: {
            gifsrc: "https://media.giphy.com/media/vSzXAvP9MxbvfI9SnY/giphy.gif",
            text: {
                content: ["Bon à savoir !", "On vous préviendra s'il y a du nouveau sur vos lignes"],
                fz: 24,
                position: "center",
            }
        },
        title: {
            gifsrc: "https://media.giphy.com/media/wFnIRSkUYbUJQ8WMAK/giphy.gif",
            text: {
                content: ["Super !"],
                fz: 44,
                position: "center"
            }
        },
        infos: {
            gifsrc: null,
            text: null
        },
        bus: {
            gifsrc: "https://media.giphy.com/media/vSzXAvP9MxbvfI9SnY/giphy.gif",
            text: {
                content: ["Bon à savoir !", "On vous préviendra s'il y a du nouveau sur vos lignes"],
                fz: 24,
                position: "center",
            }
        },
        tram: {
            gifsrc: "https://media.giphy.com/media/vSzXAvP9MxbvfI9SnY/giphy.gif",
            text: {
                content: ["Bon à savoir !", "On vous préviendra s'il y a du nouveau sur vos lignes"],
                fz: 24,
                position: "center",
            }
        },
        transilien: {
            gifsrc: "https://media.giphy.com/media/vSzXAvP9MxbvfI9SnY/giphy.gif",
            text: {
                content: ["Bon à savoir !", "On vous préviendra s'il y a du nouveau sur vos lignes"],
                fz: 24,
                position: "center",
            }
        },
        rer: {
            gifsrc: "https://media.giphy.com/media/vSzXAvP9MxbvfI9SnY/giphy.gif",
            text: {
                content: ["Bon à savoir !", "On vous préviendra s'il y a du nouveau sur vos lignes"],
                fz: 24,
                position: "center",
            }
        },
    }


    return (
        <div className="App">
            <div className="container-color">
                <div className="container">
                    <TransitionContext.Provider value={transitionsContent}>
                        <StepContext.Provider value={{step, setStep}}>
                                <Bar/>
                                <AnimatePresence mode='wait'>
                                    <Routes location={location} key={location.pathname}>
                                        <Route path='*' element={<Home setFirstname={setFirstname}/>}/>
                                        <Route path='/mode' element={
                                            <ProtectedRoute>
                                                <Mode delayOut={5} stepNum={0}/>
                                            </ProtectedRoute>
                                        }/>

                                        <Route path='/lignes-metro' element={
                                            <ProtectedRoute>
                                                <Line delayOut={5} redirect={"/titres-de-transport"} stepNum={1}/>
                                            </ProtectedRoute>
                                        }/>

                                        <Route path='/lignes-bus' element={
                                            <ProtectedRoute>
                                                <Bus delayOut={5} redirect={"/titres-de-transport"} stepNum={1}/>
                                            </ProtectedRoute>
                                        }/>

                                        <Route path='/lignes-tram' element={
                                            <ProtectedRoute>
                                                <Tram delayOut={5} redirect={"/titres-de-transport"} stepNum={1}/>
                                            </ProtectedRoute>
                                        }/>

                                        <Route path='/lignes-transilien' element={
                                            <ProtectedRoute>
                                                <Transilien delayOut={5} redirect={"/titres-de-transport"} stepNum={1}/>
                                            </ProtectedRoute>
                                        }/>

                                        <Route path='/lignes-rer' element={
                                            <ProtectedRoute>
                                                <Rer delayOut={5} redirect={"/titres-de-transport"} stepNum={1} />
                                            </ProtectedRoute>
                                        }/>

                                        <Route path='/titres-de-transport' element={
                                            <ProtectedRoute>
                                                <TransportTitle delayOut={5} redirect={"/thematiques"} stepNum={2}/>
                                            </ProtectedRoute>
                                        }/>


                                        <Route path='/thematiques' element={
                                            <ProtectedRoute>
                                                <Infos delayOut={0.5} redirect={"/on-vous-emmene"} stepNum={3}/>
                                            </ProtectedRoute>
                                        }/>

                                        <Route path='/on-vous-emmene' element={
                                            <ProtectedRoute>
                                                <End/>
                                            </ProtectedRoute>
                                        }/>

                                    </Routes>
                                </AnimatePresence>
                        </StepContext.Provider>
                    </TransitionContext.Provider>
                </div>
            </div>
        </div>
    )
}

export default App;
