import React from "react";
import "pathseg";
import Matter from "matter-js";
import BonsPlans from "../assets/img/infos/bonsPlans.svg";
import AnimationsFamille from "../assets/img/infos/animationsFamille.svg";
import Balades from "../assets/img/infos/balades.svg";
import PassesTarifs from "../assets/img/infos/passesTarifs.svg";
import InfosTrafic from "../assets/img/infos/infosTrafic.svg";
import MobilitesDouces from "../assets/img/infos/mobilitesDouces.svg";
import ServicesRATP from "../assets/img/infos/servicesRATP.svg";
import BonsPlansHover from "../assets/img/infos/hover/bonsPlans.svg";
import AnimationsFamilleHover from "../assets/img/infos/hover/animationsFamille.svg";
import BaladesHover from "../assets/img/infos/hover/balades.svg";
import PassesTarifsHover from "../assets/img/infos/hover/passesTarifs.svg";
import InfosTraficHover from "../assets/img/infos/hover/infosTrafic.svg";
import MobilitesDoucesHover from "../assets/img/infos/hover/mobilitesDouces.svg";
import ServicesRATPHover from "../assets/img/infos/hover/servicesRATP.svg";
import ValidateButton from "../components/ValidateButton/ValidateButton";
import Question from "../components/Question/Question";
import {Navigate} from "react-router";
import {createResponse} from "../services/responseService";
import {toast, ToastContainer} from "react-toastify";
import PageTransition from "../components/PageTransition";
import {StepContext} from "../contexts/StepContext";
import Back from "../components/Back/Back";

class Infos extends React.Component {
    static contextType = StepContext;
    renderForCallback = null;

    constructor(props) {
        super(props);
        this.state = {
            choices: localStorage.getItem('question4') ? localStorage.getItem('question4').split(',') : [],
            user_id: localStorage.getItem('user_id'),
            forward: false,
            willRedirect: false,
        };
    }

    getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
    }

    setChoices(choice) {
        const index = this.state.choices.indexOf(choice);
        let choices = this.state.choices;
        if (index === -1) {
            choices.push(choice);
        } else {
            choices.splice(index, 1);
        }

        this.setState({choices: choices})
    }


    submitResponse = (e) => {
        let choicesString = this.state.choices.toString();
        let dataResponse = {
            'user_id': this.state.user_id,
            'question_id': 4,
            'response': choicesString,
        };
        createResponse(dataResponse).then((res) => {
            localStorage.setItem('question4', choicesString);
            this.setState({...this.state, willRedirect: true});
            setTimeout(() => {
                this.setState({...this.state, forward: true});
            }, this.props.delayOut * 500)
        }).catch(() => {
            toast("Une erreur s'est produite veuillez réessayer", {
                type: 'error',
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
        e.preventDefault();
    }

    componentWillUnmount() {
        Matter.Events.off(this.renderForCallback, 'afterRender');
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.context.setStep(this.props.stepNum);
        document.body.classList.add('line');
        document.body.classList.remove('end');
        document.body.classList.remove('mode');
        document.body.classList.remove('home-page');
        document.body.classList.remove('bus');
        const isLandscape = window.matchMedia('(orientation:landscape)').matches;
        const infos = ['Bons plans', 'Animations familles', 'Balades', 'Passes & tarifs', 'Infos trafics', 'Mobilités douces', 'Services RATP'];
        const infosSVG = [BonsPlans, AnimationsFamille, Balades, PassesTarifs, InfosTrafic, MobilitesDouces, ServicesRATP];
        const infosSVGH = [BonsPlansHover, AnimationsFamilleHover, BaladesHover, PassesTarifsHover, InfosTraficHover, MobilitesDoucesHover, ServicesRATPHover];
        const semicircleMaxRadius = 120;
        const maxScreenSize = window.innerWidth < 812 ? 375 : 812;
        const width = window.innerWidth > maxScreenSize ? maxScreenSize : window.innerWidth;
        let height = !isLandscape ? window.innerHeight - 125.5 :  document.querySelector('.App').clientHeight - 125.5; // 56px = taille header en px + 69.5 h1
        if(window.innerWidth > 812){
            height = window.innerHeight - 182 // 100px .page-wrapper + 82px h1
        }
        const isTinyScreen = window.innerWidth < 375;
        const isLargerMobile = window.innerWidth < 812;
        const radius = isTinyScreen ? 50.7 : (isLargerMobile ? 59.19 : 84.5);
        const radiusLittle = 16;
        const numberLittle = 10;
        const scaleIsNotActive = isTinyScreen ? 0.6 : (isLargerMobile ? 0.7 : 1);
        const scaleIsActive = isTinyScreen ? 1.1 : (isLargerMobile ? 1.2 : 1.2);
        const scaleIsActiveSvg = isTinyScreen ? 0.7 : (isLargerMobile ? 0.8 : 1.2);
        const Engine = Matter.Engine,
            Render = Matter.Render,
            World = Matter.World,
            Bodies = Matter.Bodies,
            Mouse = Matter.Mouse,
            Composite = Matter.Composite,
            MouseConstraint = Matter.MouseConstraint;
        let engine = Engine.create();
        let render = Render.create({
            element: this.refs.scene,
            engine: engine,
            options: {
                width: width,
                height: height,
                background: '#FFF',
                wireframes: false
            }
        });
        Render.setPixelRatio(render,  Math.ceil(window.devicePixelRatio));
        this.renderForCallback = render;
        let bodies = [];

        const loadImage = (url, onSuccess, onError) => {
            const img = new Image();
            img.onload = () => {
                onSuccess(img.src);
            };
            img.onerror = onError();
            img.src = url;
        };

        infos.forEach((info, num) => {
            const isActive   = this.state.choices.includes(info);
            const radiusBody = isActive ? radius * scaleIsActive : radius;
            const infoSVG    = isActive ? infosSVGH[`${num}`] : infosSVG[`${num}`];

            bodies.push(Bodies.circle(this.getRandomArbitrary(radiusBody, width - radiusBody), this.getRandomArbitrary(0, height / 2), radiusBody, {
                num: num,
                value: info,
                watchClick: true,
                this: this,
                render: {
                    sprite: {
                        texture: infoSVG,
                        xScale: isActive ? scaleIsActiveSvg : scaleIsNotActive,
                        yScale: isActive ? scaleIsActiveSvg : scaleIsNotActive
                    }
                },
                isActive: isActive
            }));
        });

        for (let i = 0; i < numberLittle; i++) {
            bodies.push(Bodies.circle(this.getRandomArbitrary(radius, width - radiusLittle), this.getRandomArbitrary(0, height / 2), radiusLittle, {
                watchClick: false,
                render: {
                    fillStyle: "#F2F2F2"
                }
            }));
        }

        const scale = window.innerWidth < maxScreenSize ? (window.innerWidth / maxScreenSize) : 1;
        const semicircle = Matter.Bodies.circle(width / 2, height, semicircleMaxRadius * scale, {
            isStatic: true,
            render: {
                fillStyle: '#0E44AB', strokeStyle: '#0E44AB',
            },
        })

        Matter.Body.scale(semicircle, scale, scale);

        let ground = Bodies.rectangle(width / 2, (height + 5), width, 10, {
            isStatic: true, render: {
                strokeStyle: 'transparent'
            }
        });
        let celling = Bodies.rectangle(width / 2, -5, width, 10, {
            isStatic: true, render: {
                strokeStyle: 'transparent'
            }
        });
        let wallLeft = Bodies.rectangle(-5, height / 2, 10, height, {
            isStatic: true, render: {
                strokeStyle: 'transparent'
            }
        });
        let wallRight = Bodies.rectangle(width + 5, height / 2, 10, height, {
            isStatic: true, render: {
                strokeStyle: 'transparent'
            }
        });
        bodies.push(semicircle, ground, wallLeft, wallRight, celling)

        // add mouse control
        let mouse = Mouse.create(render.canvas),
            mouseConstraint = MouseConstraint.create(engine, {
                mouse: mouse,
                constraint: {
                    stiffness: 0.2,
                    render: {
                        visible: false
                    }
                }
            });

        World.add(engine.world, bodies);

        Matter.Events.on(mouseConstraint, "mousedown", function (event) {
            let foundPhysics = Matter.Query.point(Composite.allBodies(engine.world), event.mouse.position);
            if (foundPhysics[0] && foundPhysics[0].watchClick) {
                let texture;
                if (foundPhysics[0].isActive) {
                    let newRadius = radius / foundPhysics[0].circleRadius;
                    Matter.Body.scale(foundPhysics[0], newRadius, newRadius);
                    foundPhysics[0].render.sprite.xScale = scaleIsNotActive;
                    foundPhysics[0].render.sprite.yScale = scaleIsNotActive;
                    texture = infosSVG[`${foundPhysics[0].num}`];
                    foundPhysics[0].isActive = false;
                    foundPhysics[0].this.setChoices(foundPhysics[0].value);
                } else {
                    Matter.Body.scale(foundPhysics[0], scaleIsActive, scaleIsActive);
                    foundPhysics[0].render.sprite.xScale = scaleIsActiveSvg;
                    foundPhysics[0].render.sprite.yScale = scaleIsActiveSvg;
                    texture = infosSVGH[`${foundPhysics[0].num}`];
                    foundPhysics[0].isActive = true;
                    foundPhysics[0].this.setChoices(foundPhysics[0].value);
                }
                loadImage(
                    texture,
                    url => {
                        foundPhysics[0].render.sprite.texture = url;
                    },
                    () => {
                    }
                );
            }
        });

        Matter.Runner.run(engine);
        Matter.Render.run(render);

        Matter.Events.on(render, "afterRender", () => {
            engine.world.bodies.forEach((body) => {
                if (body.watchClick ) {
                    if (body.angle > 0.3) {
                        Matter.Body.setAngle(body, 0.3);
                    } else if (body.angle < -0.3) {
                        Matter.Body.setAngle(body, -0.3);
                    }
                }
            });
        });
    }

    render() {


        return (
            <>
                {
                    this.state.forward
                        ? <Navigate to={this.props.redirect}/>

                        : <div className="page-wrapper">
                            <PageTransition
                                redirecting={this.state.willRedirect}
                                delay={this.props.delayOut}
                                name="infos"
                                withoutGif={true}
                            />
                            <div ref="scene" className='container-button'>
                                <ToastContainer role="alert" autoClose={5000}/>
                                <Back />
                                <Question title="Les informations que vous aimeriez recevoir..." bold="(plusieurs choix possibles)"/>
                                <ValidateButton disabled={this.state.choices.length === 0} onClick={this.submitResponse}/>
                            </div>
                        </div>
                }
            </>

        );
    }
}

export default Infos;
