import React from "react";
import Copyable from "../Copyable/Copyable";
import './Boxes.scss';


function Boxes(props) {
    return(
        <div className="boxes">
            {props.items.map((item, index) => {
                let selectedCheck = false;
                if (props.selected.length > 0) {
                    if (props.selected[0].icon === item.icon) {
                        selectedCheck = true;
                    }
                }
                return (
                    <Copyable key={item.id} item={item} index={index} selected={selectedCheck} handleClick={props.handleClick} />
                );
            })}
        </div>
    )
}

export default Boxes;
