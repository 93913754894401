import API from "../config/api";
import axios from 'axios';

export async function createResponse(dataAnswer) {
    const response = await axios.post(API.base_url + API.create_response,
        dataAnswer, {
        headers: {
            APITOKEN: process.env.REACT_APP_APITOKEN,
        }
    });
    return response.data;
}
