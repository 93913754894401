import React from 'react'
import {Navigate} from "react-router";

const ProtectedRoute = ({children}) => {
    if (!localStorage.getItem('user_id')) {
        return <Navigate to="/" replace/>;
    }

    return children;
};

export default ProtectedRoute;
