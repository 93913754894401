import React from 'react';
import {ReactComponent as Bus} from '../../assets/img/modes/bus.svg';
import {ReactComponent as Metro} from '../../assets/img/modes/metro.svg';
import {ReactComponent as Rer} from '../../assets/img/modes/rer.svg';
import {ReactComponent as Transilien} from '../../assets/img/modes/transilien.svg';
import {ReactComponent as MobilitesDouces} from '../../assets/img/modes/mobilites_douces.svg';
import {ReactComponent as Tram} from '../../assets/img/modes/tram.svg';

const icons = {
    'bus': Bus,
    'metro': Metro,
    'rer': Rer,
    'transilien': Transilien,
    'mobilites_douces': MobilitesDouces,
    'tram': Tram,
}

// See: https://reactjs.org/docs/jsx-in-depth.html#choosing-the-type-at-runtime

function Icon(props) {
    const IconSVG = icons[props.icons];
    return <IconSVG className={props.className}/>;
}

export default Icon;
