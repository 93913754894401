import {motion} from "framer-motion";
import Icon from "../Icon/Icon";
import React from "react";

const HeaderText = (props) =>{
    return(
        <div className="header-text">
            <motion.div
                animate={{opacity: 1, y: [-300, -20, 20, 1], x: [-4, -2, 2, 1]}}
                transition={{
                    default: {
                        duration: 0.4,
                    },
                }}
            >
                <Icon className="icon tram" icons={'transilien'}/>
            </motion.div>
            <p className='white-text'>Le</p>
            <p className="uppercase pink-text">Petit</p>
            <motion.div initial={{opacity: 0, scale: 0.5}}
                        animate={{opacity: 1, scale: 1}}
                        transition={{
                            default: {
                                duration: 0.1,
                                ease: [0, 0.71, 0.2, 1.01]
                            },
                            scale: {
                                type: "spring",
                                damping: 10,
                                stiffness: 400,
                                restDelta: 0.001
                            }
                        }}
                        className="round bg-violet little"></motion.div>
            <p className="blue-text">Questionnaire</p>
            <motion.div
                animate={{opacity: 1, y: [-300, -20, 20, 1], x: [-4, -2, 2, 1]}}
                transition={{
                    default: {
                        duration: 0.4,
                    },
                }}
            ><Icon className="icon rer" icons={'rer'}/></motion.div>
            <motion.div initial={{opacity: 0, scale: 0.5}}
                        animate={{opacity: 1, scale: 1}}
                        transition={{
                            default: {
                                duration: 0.1,
                                ease: [0, 0.71, 0.2, 1.01]
                            },
                            scale: {
                                type: "spring",
                                damping: 10,
                                stiffness: 400,
                                restDelta: 0.001
                            }
                        }}
                        className="round bg-yellow"></motion.div>
            {props.ratpId && props.name ?
                <p className="orange-text name"><span className="green-text">de</span> {props.name}</p>
                :
                <p className="orange-text">maRATP</p>
            }
            <motion.div
                animate={{opacity: 1, y: [-300, 100, -300, 100, 1], x: [-60, 20, -60, 20, 1]}}
                transition={{
                    default: {
                        duration: 0.3,
                        ease: [0, 0.2, 0.4, 1]
                    },
                }}
            ><Icon className="icon metro" icons={'metro'}/></motion.div>
        </div>
    )
}
export default HeaderText;
