import React from "react";
import "pathseg";
import Matter from "matter-js";
import RERA from "../assets/img/rer/rerA.svg";
import RERB from "../assets/img/rer/rerB.svg";
import RERC from "../assets/img/rer/rerC.svg";
import RERD from "../assets/img/rer/rerD.svg";
import RERE from "../assets/img/rer/rerE.svg";
import RERAHover from "../assets/img/rer/hover/rerA.svg";
import RERBHover from "../assets/img/rer/hover/rerB.svg";
import RERCHover from "../assets/img/rer/hover/rerC.svg";
import RERDHover from "../assets/img/rer/hover/rerD.svg";
import REREHover from "../assets/img/rer/hover/rerE.svg";
import ValidateButton from "../components/ValidateButton/ValidateButton";
import Question from "../components/Question/Question";
import {Navigate} from "react-router";
import {createResponse} from "../services/responseService";
import {toast, ToastContainer} from "react-toastify";
import PageTransition from "../components/PageTransition";
import {StepContext} from "../contexts/StepContext";
import Back from "../components/Back/Back";

class Rer extends React.Component {
    static contextType = StepContext;
    renderForCallback = null;

    constructor(props) {
        super(props);
        this.state = {
            choices: localStorage.getItem('question2rer') ? localStorage.getItem('question2rer').split(',') : [],
            forward: false,
            willRedrect: false,
            user_id: localStorage.getItem('user_id'),
        };
    }

    getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
    }

    setChoices(choice) {
        const index = this.state.choices.indexOf(choice);
        let choices = this.state.choices;
        if (index === -1) {
            choices.push(choice);
        } else {
            choices.splice(index, 1);
        }
        this.setState({choices})
    }


    submitResponse = (e) => {
        let choicesString = this.state.choices.toString();
        let dataResponse = {
            'user_id': this.state.user_id,
            'question_id': 2,
            'response': choicesString,
        };
        createResponse(dataResponse).then((res) => {
            localStorage.setItem('question2rer', choicesString);
            this.setState({...this.state, willRedirect: true});
            setTimeout(() => {
                this.setState({...this.state, forward: true});
            }, this.props.delayOut * 800)
        }).catch(() => {
            toast("Une erreur s'est produite veuillez réessayer", {
                type: 'error',
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
        e.preventDefault();
    }

    componentWillUnmount() {
        Matter.Events.off(this.renderForCallback, 'afterRender');
    }

    componentDidMount() {
        //window.scrollTo(0, 0);
        this.context.setStep(this.props.stepNum);
        document.body.classList.add('line');
        document.body.classList.remove('end');
        document.body.classList.remove('mode');
        document.body.classList.remove('home-page');
        document.body.classList.remove('bus');
        const isLandscape = window.matchMedia('(orientation:landscape)').matches;
        const rerTitles = ['RER A', 'RER B', 'RER C', 'RER D', 'RER E'];
        const rerTitlesSVG = [RERA, RERB, RERC, RERD, RERE];
        const rerTitlesSVGH = [RERAHover, RERBHover, RERCHover, RERDHover, REREHover];
        const semicircleMaxRadius = 120;
        const maxScreenSize = window.innerWidth < 812 ? 375 : 812;
        const width = window.innerWidth > maxScreenSize ? maxScreenSize : window.innerWidth;
        let height = !isLandscape ? window.innerHeight - 147.5 :  document.querySelector('.App').clientHeight - 147.5; // 56px .page-wrapper + 69.5 h1 + 22px with the new text little
        if(window.innerWidth > 812){
            height = window.innerHeight - 208.5 // 100px .page-wrapper + 82px h1 + 26.5px .description-title
        }
        const isTinyScreen = window.innerWidth < 375;
        const isLargerMobile = window.innerWidth < 812;
        const sizeBox = isTinyScreen ? 63 : (isLargerMobile ? 88.2 : 163.8);
        const radiusLittle = 16;
        const numberLittle = 10;
        const chamferRadius = isTinyScreen ? 10 : (isLargerMobile ? 15 : 40)
        const scaleIsNotActive = isTinyScreen ? 0.5 : (isLargerMobile ? 0.7 : 1.3);
        const scaleIsActive = isTinyScreen ? 1.1 : (isLargerMobile ? 1.1 : 1.3);
        const scaleIsActiveSvg = isTinyScreen ? 0.6 : (isLargerMobile ? 0.8 : 1.6);
        const Engine = Matter.Engine,
            Render = Matter.Render,
            World = Matter.World,
            Bodies = Matter.Bodies,
            Mouse = Matter.Mouse,
            Composite = Matter.Composite,
            MouseConstraint = Matter.MouseConstraint;
        let engine = Engine.create();
        let render = Render.create({
            element: this.refs.scene,
            engine: engine,
            options: {
                width: width,
                height: height,
                background: '#FFF',
                wireframes: false,
            }
        });
        this.renderForCallback = render;

        Render.setPixelRatio(render, Math.ceil(window.devicePixelRatio));
        let bodies = [];

        const loadImage = (url, onSuccess, onError) => {
            const img = new Image();
            img.onload = () => {
                onSuccess(img.src);
            };
            img.onerror = onError();
            img.src = url;
        };

        rerTitles.forEach((title, num) => {
            const isActive         = this.state.choices.includes(title);
            const sizeBoxBody      = isActive ? sizeBox * scaleIsActive : sizeBox;
            const titlesSVGElement = isActive ? rerTitlesSVGH[`${num}`] : rerTitlesSVG[`${num}`];

            bodies.push(Bodies.rectangle(this.getRandomArbitrary(sizeBoxBody / 2, width - (sizeBoxBody / 2)), this.getRandomArbitrary(0, height / 2), sizeBoxBody, sizeBoxBody, {
                num: num,
                value: title,
                widthElem: sizeBox,
                watchClick: true,
                this: this,
                isActive: isActive,
                chamfer: {radius: chamferRadius},
                render: {
                    strokeStyle: 'red',
                    sprite: {
                        texture: titlesSVGElement,
                        xScale: isActive ? scaleIsActiveSvg : scaleIsNotActive,
                        yScale: isActive ? scaleIsActiveSvg : scaleIsNotActive,
                    },
                },
            }));
        });

        for (let i = 0; i < numberLittle; i++) {
            bodies.push(Bodies.circle(this.getRandomArbitrary(radiusLittle, width - radiusLittle), this.getRandomArbitrary(0, height / 2), radiusLittle, {
                watchClick: false,
                render: {
                    fillStyle: "#F2F2F2"
                }
            }));
        }

        const scale = window.innerWidth < maxScreenSize ? (window.innerWidth / maxScreenSize) : 1;
        const semicircle = Matter.Bodies.circle(width / 2, height, semicircleMaxRadius * scale, {
            isStatic: true,
            render: {
                fillStyle: '#0E44AB', strokeStyle: '#0E44AB',
            },
        })


        Matter.Body.scale(semicircle, scale, scale);

        let ground = Bodies.rectangle(width / 2, (height + 5), width, 10, {
            isStatic: true, render: {
                strokeStyle: 'transparent'
            }
        });
        let celling = Bodies.rectangle(width / 2, -5, width, 10, {
            isStatic: true, render: {
                strokeStyle: 'transparent'
            }
        });
        let wallLeft = Bodies.rectangle(-5, height / 2, 10, height, {
            isStatic: true, render: {
                strokeStyle: 'transparent'
            }
        });
        let wallRight = Bodies.rectangle(width + 5, height / 2, 10, height, {
            isStatic: true, render: {
                strokeStyle: 'transparent'
            }
        });
        bodies.push(semicircle, ground, wallLeft, wallRight, celling)

        // add mouse control
        let mouse = Mouse.create(render.canvas),
            mouseConstraint = MouseConstraint.create(engine, {
                mouse: mouse,
                constraint: {
                    stiffness: 0.2,
                    render: {
                        visible: false
                    }
                }
            });

        World.add(engine.world, bodies);

        Matter.Events.on(mouseConstraint, "mousedown", function (event) {
            let foundPhysics = Matter.Query.point(Composite.allBodies(engine.world), event.mouse.position);
            if (foundPhysics[0] && foundPhysics[0].watchClick) {
                let texture;
                if (foundPhysics[0].isActive) {
                    let newScale = foundPhysics[0].widthElem / (foundPhysics[0].widthElem * scaleIsActive);
                    Matter.Body.scale(foundPhysics[0], newScale, newScale);
                    foundPhysics[0].render.sprite.xScale = scaleIsNotActive;
                    foundPhysics[0].render.sprite.yScale = scaleIsNotActive;
                    texture = rerTitlesSVG[`${foundPhysics[0].num}`];
                    foundPhysics[0].isActive = false;
                    foundPhysics[0].this.setChoices(foundPhysics[0].value);
                } else {
                    Matter.Body.scale(foundPhysics[0], scaleIsActive, scaleIsActive);
                    foundPhysics[0].render.sprite.xScale = scaleIsActiveSvg;
                    foundPhysics[0].render.sprite.yScale = scaleIsActiveSvg;
                    texture = rerTitlesSVGH[`${foundPhysics[0].num}`];
                    foundPhysics[0].isActive = true;
                    foundPhysics[0].this.setChoices(foundPhysics[0].value);
                }
                loadImage(
                    texture,
                    url => {
                        foundPhysics[0].render.sprite.texture = url;
                    },
                    () => {
                    }
                );
            }
        });

        Matter.Runner.run(engine);
        Matter.Render.run(render);

        Matter.Events.on(render, "afterRender", () => {
            engine.world.bodies.forEach((body) => {
                if (body.watchClick ) {
                    if (body.angle > 0.3) {
                        Matter.Body.setAngle(body, 0.3);
                    } else if (body.angle < -0.3) {
                        Matter.Body.setAngle(body, -0.3);
                    }
                }
            });
        });
    }

    render() {
        return (
            <>
                {this.state.forward ? <Navigate to={this.props.redirect}/> :
                    <div className="page-wrapper">
                        <PageTransition
                            redirecting={this.state.willRedirect}
                            delay={this.props.delayOut}
                            name="rer"
                        />
                        <div ref="scene" className='container-button'>
                            <ToastContainer role="alert" autoClose={5000}/>
                            <Back />
                            <Question title="En général, vous voyagez..." bold="sur quelle(s) ligne(s) ?"
                                      little="(plusieurs choix possibles)"/>
                            <ValidateButton disabled={this.state.choices.length === 0} onClick={this.submitResponse}/>
                        </div>
                    </div>}
            </>
        );
    }
}

export default Rer;
