import React, {Component} from 'react';
import './ValidateButton.scss';

class ValidateButton extends Component {
    render() {
        return (
            <div className='validateButton'>
                <button onClick={this.props.onClick} aria-disabled={this.props.disabled} disabled={this.props.disabled}><span>Valider</span></button>
            </div>
        );
    }
}

export default ValidateButton;
