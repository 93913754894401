import React from "react";
import './Line.scss';
import "pathseg";
import Matter from "matter-js";
import M1 from "../../assets/img/metros/M1.svg";
import M2 from "../../assets/img/metros/M2.svg";
import M3 from "../../assets/img/metros/M3.svg";
import M3bis from "../../assets/img/metros/M3bis.svg";
import M4 from "../../assets/img/metros/M4.svg";
import M5 from "../../assets/img/metros/M5.svg";
import M6 from "../../assets/img/metros/M6.svg";
import M7 from "../../assets/img/metros/M7.svg";
import M7bis from "../../assets/img/metros/M7bis2.svg";
import M8 from "../../assets/img/metros/M8.svg";
import M9 from "../../assets/img/metros/M9.svg";
import M10 from "../../assets/img/metros/M10.svg";
import M11 from "../../assets/img/metros/M11.svg";
import M12 from "../../assets/img/metros/M12.svg";
import M13 from "../../assets/img/metros/M13.svg";
import M14 from "../../assets/img/metros/M14.svg";
import M1Hover from "../../assets/img/metros/hover/M1.svg";
import M2Hover from "../../assets/img/metros/hover/M2.svg";
import M3Hover from "../../assets/img/metros/hover/M3.svg";
import M3bisHover from "../../assets/img/metros/hover/M3bis.svg";
import M4Hover from "../../assets/img/metros/hover/M4.svg";
import M5Hover from "../../assets/img/metros/hover/M5.svg";
import M6Hover from "../../assets/img/metros/hover/M6.svg";
import M7Hover from "../../assets/img/metros/hover/M7.svg";
import M7bisHover from "../../assets/img/metros/hover/M7bis2.svg";
import M8Hover from "../../assets/img/metros/hover/M8.svg";
import M9Hover from "../../assets/img/metros/hover/M9.svg";
import M10Hover from "../../assets/img/metros/hover/M10.svg";
import M11Hover from "../../assets/img/metros/hover/M11.svg";
import M12Hover from "../../assets/img/metros/hover/M12.svg";
import M13Hover from "../../assets/img/metros/hover/M13.svg";
import M14Hover from "../../assets/img/metros/hover/M14.svg";
import ValidateButton from "../../components/ValidateButton/ValidateButton";
import Question from "../../components/Question/Question";
import {Navigate} from "react-router";
import {createResponse} from "../../services/responseService";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PageTransition from "../../components/PageTransition";
import {StepContext} from "../../contexts/StepContext";
import Back from "../../components/Back/Back";

class Line extends React.Component {
    static contextType = StepContext;
    renderForCallback = null;

    constructor(props) {
        super(props);
        this.state = {
            choices: localStorage.getItem('question2metro') ? localStorage.getItem('question2metro').split(',') : [],
            user_id: localStorage.getItem('user_id'),
            forward: false,
            willRedirect: false,
        };
    }

    getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
    }

    setChoices(choice) {
        const index = this.state.choices.indexOf(choice);
        let choices = this.state.choices;
        if (index === -1) {
            choices.push(choice);
        } else {
            choices.splice(index, 1);
        }

        this.setState({choices: choices})
    }


    submitResponse = (e) => {
        let choicesString = this.state.choices.toString();
        let dataResponse = {
            'user_id': this.state.user_id,
            'question_id': 2,
            'response': choicesString,
        };
        createResponse(dataResponse).then((res) => {
            localStorage.setItem('question2metro', choicesString);
            this.setState({...this.state, willRedirect: true});
            setTimeout(() => {
                this.setState({...this.state, forward: true});
            }, this.props.delayOut * 800)
        }).catch(() => {
            toast("Une erreur s'est produite veuillez réessayer", {
                type: 'error', position: toast.POSITION.BOTTOM_CENTER
            });
        });
        e.preventDefault();
    }

    componentWillUnmount() {
        Matter.Events.off(this.renderForCallback, 'afterRender');
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.context.setStep(this.props.stepNum)
        document.body.classList.add('line');
        document.body.classList.remove('end');
        document.body.classList.remove('mode');
        document.body.classList.remove('home-page');
        document.body.classList.remove('bus');
        const isLandscape = window.matchMedia('(orientation:landscape)').matches;
        const metroLines = ['1', '2', '3', '3bis', '4', '5', '6', '7', '7bis', '8', '9', '10', '11', '12', '13', '14'];
        const metroLinesSVG = [M1, M2, M3, M3bis, M4, M5, M6, M7, M7bis, M8, M9, M10, M11, M12, M13, M14];
        const metroLinesSVGH = [M1Hover, M2Hover, M3Hover, M3bisHover, M4Hover, M5Hover, M6Hover, M7Hover, M7bisHover, M8Hover, M9Hover, M10Hover, M11Hover, M12Hover, M13Hover, M14Hover];
        const semicircleMaxRadius = 120;
        const maxScreenSize = window.innerWidth < 812 ? 375 : 812;
        const width = window.innerWidth > maxScreenSize ? maxScreenSize : window.innerWidth;
        let height = !isLandscape ? window.innerHeight - 147.5 :  document.querySelector('.App').clientHeight - 147.5; // 56px .page-wrapper + 69.5 h1 + 22px with the new text little
        if(window.innerWidth > 812){
            height = window.innerHeight - 208.5 // 100px .page-wrapper + 82px h1 + 26.5px .description-title
        }
        const isTinyScreen = window.innerWidth < 375;
        const isLargerMobile = window.innerWidth < 812;
        const radius = isTinyScreen ? 24 : (isLargerMobile ? 32 : 56);
        const radiusLittle = 16;
        const numberLittle = 10;
        const scaleIsNotActive = isTinyScreen ? 0.6 : (isLargerMobile ? 0.8 : 1.4);
        const scaleIsActive = isTinyScreen ? 1.4 : (isLargerMobile ? 1.3 : 1.5);
        const scaleIsActiveSvg = isTinyScreen ? 0.8 : (isLargerMobile ? 1 : 1.8);
        const Engine = Matter.Engine, Render = Matter.Render, World = Matter.World, Bodies = Matter.Bodies,
            Mouse = Matter.Mouse, Composite = Matter.Composite, MouseConstraint = Matter.MouseConstraint;
        let engine = Engine.create();
        let render = Render.create({
            element: this.refs.scene,
            engine: engine,
            options: {
                width: width, height: height, background: '#FFF', wireframes: false
            }
        });
        this.renderForCallback = render;
        Render.setPixelRatio(render,  Math.ceil(window.devicePixelRatio));
        let bodies = [];

        const loadImage = (url, onSuccess, onError) => {
            const img = new Image();
            img.onload = () => {
                onSuccess(img.src);
            };
            img.onerror = onError();
            img.src = url;
        };

        metroLines.forEach((line, num) => {
            const isActive   = this.state.choices.includes(line);
            const radiusBody = isActive ? radius * scaleIsActive : radius;
            const lineSVG    = isActive ? metroLinesSVGH[`${num}`] : metroLinesSVG[`${num}`];

            bodies.push(Bodies.circle(this.getRandomArbitrary(radiusBody, width - radiusBody), this.getRandomArbitrary(0, height / 2), radiusBody, {
                num: num, value: line, watchClick: true, this: this, render: {
                    sprite: {
                        texture: lineSVG,
                        xScale: isActive ? scaleIsActiveSvg : scaleIsNotActive,
                        yScale: isActive ? scaleIsActiveSvg : scaleIsNotActive
                    }
                },
                isActive: isActive
            }));
        });

        for (let i = 0; i < numberLittle; i++) {
            bodies.push(Bodies.circle(this.getRandomArbitrary(radius, width - radiusLittle), this.getRandomArbitrary(0, height / 2), radiusLittle, {
                watchClick: false, render: {
                    fillStyle: "#F2F2F2"
                }
            }));
        }

        const scale = window.innerWidth < maxScreenSize ? (window.innerWidth / maxScreenSize) : 1;
        const semicircle = Matter.Bodies.circle(width / 2, height, semicircleMaxRadius * scale, {
            isStatic: true,
            render: {
                fillStyle: '#0E44AB', strokeStyle: '#0E44AB',
            },
        });

        Matter.Body.scale(semicircle, scale, scale);

        let ground = Bodies.rectangle(width / 2, height + 5, width, 10, {
            isStatic: true,
            render: {
                strokeStyle: 'transparent'
            }
        });
        let celling = Bodies.rectangle(width / 2, -5, width, 10, {
            isStatic: true,
            render: {
                strokeStyle: 'transparent'
            }
        });
        let wallLeft = Bodies.rectangle(-5, height / 2, 10, height, {
            isStatic: true,
            render: {
                strokeStyle: 'transparent'
            }
        });
        let wallRight = Bodies.rectangle(width + 5, height / 2, 10, height, {
            isStatic: true,
            render: {
                strokeStyle: 'transparent'
            }
        });
        bodies.push(semicircle, ground, wallLeft, wallRight, celling)

        // add mouse control
        let mouse = Mouse.create(render.canvas), mouseConstraint = MouseConstraint.create(engine, {
            mouse: mouse, constraint: {
                stiffness: 0.2,
                render: {
                    visible: false
                }
            }
        });

        World.add(engine.world, bodies);

        Matter.Events.on(mouseConstraint, "mousedown", function (event) {
            let foundPhysics = Matter.Query.point(Composite.allBodies(engine.world), event.mouse.position);
            if (foundPhysics[0] && foundPhysics[0].watchClick) {
                let texture;
                if (foundPhysics[0].isActive) {
                    let newRadius = radius / foundPhysics[0].circleRadius;
                    Matter.Body.scale(foundPhysics[0], newRadius, newRadius);
                    foundPhysics[0].render.sprite.xScale = scaleIsNotActive;
                    foundPhysics[0].render.sprite.yScale = scaleIsNotActive;
                    texture = metroLinesSVG[`${foundPhysics[0].num}`];
                    foundPhysics[0].isActive = false;
                    foundPhysics[0].this.setChoices(foundPhysics[0].value);
                } else {
                    Matter.Body.scale(foundPhysics[0], scaleIsActive, scaleIsActive);
                    foundPhysics[0].render.sprite.xScale = scaleIsActiveSvg;
                    foundPhysics[0].render.sprite.yScale = scaleIsActiveSvg;
                    texture = metroLinesSVGH[`${foundPhysics[0].num}`];
                    foundPhysics[0].isActive = true;
                    foundPhysics[0].this.setChoices(foundPhysics[0].value);
                }
                loadImage(texture, url => {
                    foundPhysics[0].render.sprite.texture = url;
                }, () => {
                });
            }
        });

        Matter.Runner.run(engine);
        Matter.Render.run(render);

        Matter.Events.on(render, "afterRender", () => {
            engine.world.bodies.forEach((body) => {
                if (body.watchClick ) {
                    if (body.angle > 0.3) {
                        Matter.Body.setAngle(body, 0.3);
                    } else if (body.angle < -0.3) {
                        Matter.Body.setAngle(body, -0.3);
                    }
                }
            });
        });
    }

    render() {
        return (<>
            {this.state.forward ? <Navigate to={this.props.redirect}/> : <div className="page-wrapper">
                <PageTransition
                    redirecting={this.state.willRedirect}
                    delay={this.props.delayOut}
                    name="line"
                />
                <div ref="scene" className='container-button'>
                    <ToastContainer role="alert" autoClose={5000}/>
                    <Back />
                    <Question title="En général, vous voyagez..." bold="sur quelle(s) ligne(s) ?"
                              little="(plusieurs choix possibles)"/>
                    <ValidateButton disabled={this.state.choices.length === 0}
                                    onClick={this.submitResponse}/>
                </div>
            </div>}
        </>);
    }
}

export default Line;
