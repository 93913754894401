import API from "../config/api";
import axios from 'axios';

export async function createUser(dataUser) {
    const response = await axios.post(API.base_url + API.create_user,
        dataUser, {
        headers: {
            APITOKEN: process.env.REACT_APP_APITOKEN,
        }
    });
    return response.data;
}
